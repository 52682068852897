@font-face {
  font-family: 'zx spectrum';
  src: local('zx spectrum'), url(assets/zx-spectrum.ttf) format('truetype');
}

body {
  overflow: hidden;
}

div#stats {
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 500;
  opacity: 0.8;
  user-select: none;
}

#stats > canvas {
  margin: unset;
}

.planet-gen-stats {
  position: absolute !important;
  top: 60px !important;
}

::view-transition-old(header),
::view-transition-new(header) {
  height: 100%;
}
